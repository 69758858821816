<template>
    <div>
        <template v-if="!send_verification_code">
        <b-form-group
            :label="$t('original_currency') + ':'"
            id="original_currency"
            label-for="original-currency"
        >
            <b-input-group>
                <b-input-group-prepend
                    class="original_currency"
                    id="original-currency"
                >
                    <b-form-select
                        :options="currencyOptions"
                        @change="change(
                                    exchangeCurrenciesData.original_currency,
                                    exchangeCurrenciesData.exchange_currency,
                                    exchangeCurrenciesData.original_amount,
                                    'change1',
                                )"
                        v-model="exchangeCurrenciesData.original_currency"
                    >
                    </b-form-select>
                </b-input-group-prepend>
                <b-form-input
                    :placeholder="placeholder"
                    @input="change(
                                    exchangeCurrenciesData.original_currency,
                                    exchangeCurrenciesData.exchange_currency,
                                    exchangeCurrenciesData.original_amount,
                                    'change1'
                                )"
                    class="form-control"
                    id="original-currency-input"
                    step="0.01"
                    type="number"
                    v-model="exchangeCurrenciesData.original_amount"
                >
                </b-form-input>
            </b-input-group>
        </b-form-group>


        <b-form-group
            :label="$t('exchange_currency') + ':'"
            id="exchange_currency"
            label-for="exchange-currency"
        >
            <b-input-group>
                <b-input-group-prepend
                    class="exchange_currency"
                    id="exchange-currency"
                >
                    <b-form-select
                        :options="currencyOptions"
                        @change="change(
                                    exchangeCurrenciesData.original_currency,
                                    exchangeCurrenciesData.exchange_currency,
                                    exchangeCurrenciesData.original_amount,
                                    'change1'
                                )"
                        v-model="exchangeCurrenciesData.exchange_currency"
                    >
                    </b-form-select>
                </b-input-group-prepend>
                <b-form-input
                    :placeholder="placeholder"
                    @input="change(
                                    exchangeCurrenciesData.exchange_currency,
                                    exchangeCurrenciesData.original_currency,
                                    exchangeCurrenciesData.exchange_amount,
                                    'change2'
                                )"
                    class="form-control"
                    id="exchange-currency-input"
                    step="0.01"
                    type="number"
                    v-model="exchangeCurrenciesData.exchange_amount"
                >
                </b-form-input>
            </b-input-group>
        </b-form-group>
        </template>
        <template v-else>
            <b-form-group :label="$t('code')" label-for="code">
                <validation-provider #default="{ errors }" name="code">
                    <b-form-input id="code" placeholder="****" v-model.trim="verification_code" rules="reaquired"/>
                    <small style="color: red;">{{errorMessage}}</small>
                </validation-provider>
            </b-form-group>
        </template>
        <p style="font-size: 12px;">{{ $t('exchange_modal_text1') }}</p>
        <p style="font-size: 12px;">{{ $t('exchange_modal_text2') }}</p>
        <p style="font-size: 12px;"><b>{{ $t('exchange_modal_point1_heading') }}</b>{{ $t('exchange_modal_point1_text') }}</p>
        <p style="font-size: 12px;"><b>{{ $t('exchange_modal_point2_heading') }}</b>{{ $t('exchange_modal_point2_text') }}</p>
        <p style="font-size: 12px;"><b>{{ $t('exchange_modal_point3_heading') }}</b>{{ $t('exchange_modal_point3_text') }}</p>
        <p style="font-size: 12px;"><b>{{ $t('exchange_modal_point4_heading') }}</b>{{ $t('exchange_modal_point4_text') }}</p>
        <p style="font-size: 12px;"><b>{{ $t('exchange_modal_point5_heading') }}</b>{{ $t('exchange_modal_point5_text') }}</p>


        <b-form-checkbox class="mb-1" v-model="accept_conditions">{{ $t('accept') }}</b-form-checkbox>
        <p class="mb-2" style="color: red; font-size: 11px;" v-if="acceptConditionsErrorMessage">{{acceptConditionsErrorMessage}}</p>

        <b-button @click="ExchangeAmount" variant="outline-primary">Change</b-button>

        <!--<p class="d-flex justify-content-around my-1">-->
        <!--<span v-if="amount_fee">{{ 'fee_hive' }}: <strong>{{ amount_fee }}</strong></span>-->
        <!--<span v-if="amount_total">{{ 'total' }}: <strong>{{ amount_total }}</strong></span>-->
        <!--<span v-if="exchanged_amount">{{ 'exchanged_amount' }}: <strong>{{ exchanged_amount }}</strong></span>-->
        <!--</p>-->

    </div>
</template>

<script>

  import {ValidationObserver, ValidationProvider} from "vee-validate";
  import {
    BButton,
    BCardText,
    BCardTitle,
    BCol,
    BForm,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BInputGroup,
    BLink,
    BRow
  } from "bootstrap-vue";
  import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
  import {CurrencyAmountFormat} from '@core/utils/CurrencyAmountFormat';

  export default {
    props: {
      changeTransactionTableType: Function,
    },
    components: {
      BRow, BCol, BLink, BFormGroup, BFormInput, BFormCheckbox, BCardText,
      BCardTitle, BForm, BButton, BInputGroup, BFormSelect, ValidationProvider, ValidationObserver
    },
    data() {
      return {
        amount_fee: null,
        amount_total: null,
        exchanged_amount: null,
        currencies: null,
        currency_name: null,
        exchange_currency_name: null,
        exchangeCurrenciesData: {
          original_currency: 1,
          original_amount: 1,
          exchange_currency: 1,
          exchange_amount: 1,
          real_amount: null,
          hive_fee: null,
        },
        currencyOptions: [],
        currencyExchanges: null,
        accept_conditions: false,
        acceptConditionsErrorMessage: null,
        verification_code: null,
        send_verification_code: false,
        verification_code_bool: false,
        errorMessage: null,
        placeholder: null,
      }
    },

    created() {
      this.getCurrencies();
      this.getExchangeValues();
      this.placeholder = this.amountFormat(1234.56, "CHF", "type")
    },

    methods: {

      amountFormat(amount, currency, type) {
        return CurrencyAmountFormat(amount, currency, type)
      },

      getExchangeValues() {
        this.$http.post("/currency/get_exchange").then((response) => {
          if (response.data.status == 200) {
            this.currencyExchanges = response.data.value;
          }
        });
      },

      getCurrencies() {
        this.$http.post("/currency").then((response) => {
          if (response.data.status == 200) {
            this.currencies = response.data.value;

            this.currencies.map((item) => {
              this.currencyOptions.push({
                value: item.id,
                text: item.name,
              });
            })
          }
        });
      },

      change(value1, value2, value3, type) {

        let result = this.currencyExchanges.find(item => item.currency_id === value1 && item.currency_exchange_id === value2);

        if (result.balance) {
          result = result.balance * value3;
        } else {
          result = parseFloat(result.crypto_balance.replace(/,/g, '')) * value3;
        }

        console.log(value1, value2, value3, type);

        let discount_percentage = 1;

        if(value2 == 11) {
          discount_percentage = 2;
        }

        if(value2 == 12 || value2 == 13 || value2 == 14) {
          discount_percentage = 0.5;
        }

        this.exchangeCurrenciesData.real_amount = result;
        this.exchangeCurrenciesData.hive_fee = result * discount_percentage / 100;

          if (type == 'change1') {
            if(value1 != value2) {
              result -= result * discount_percentage / 100;
            }
            this.exchangeCurrenciesData.exchange_amount = result
          }
          if (type == 'change2') {
            if(value1 != value2) {
              result += result * discount_percentage / 100;
            }
            this.exchangeCurrenciesData.original_amount = result
          }



      },

      ExchangeAmount() {

        if(!this.accept_conditions) {
          this.acceptConditionsErrorMessage = "You must confirm that you agree with the rules"
          return
        }
        this.$http.post("/currency/create-todo", this.exchangeCurrenciesData).then((response) => {
          if (response.data.status == 200) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: this.$t(response.data.message),
                icon: "SuccessIcon",
                variant: "success",
              },
            });
            this.changeTransactionTableType('transaction');
            setTimeout(()=>{
              this.changeTransactionTableType('exchange');
            }, 10)
          } else if (response.data.status == 500) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: this.$t(response.data.message),
                icon: "SuccessIcon",
                variant: "danger",
              },
            });
          }

          this.$emit('toggle-exchange-modal')

        });
      },
    }
  }
</script>

<style scoped>

</style>